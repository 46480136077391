.customer-select{
    border: none;
    color: #000000;
    background-color: #F9FBFC;
    cursor: pointer;
}

.d-inline button{
    color: #0A4468 !important;
    background-color: transparent;
    border-color: transparent;
}

.d-inline button:hover{
    color: #0A4468 !important;
    background-color: transparent;
    border-color: transparent;
}

.d-inline button:focus{
    color: #0A4468 !important;
    background-color: transparent;
    border-color: transparent;
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}


.d-inline .toggle{
    color: #0A4468 !important;
    background-color: transparent;
    border-color: transparent;
}


.show>.btn-primary.dropdown-toggle {
    color: #0A4468 !important;
    background-color: transparent;
    border-color: transparent;
}

.available-image {
    height: 35px;
}

.text-box-width {
    width: 13%;
}

.header-button {
    background: #0A4468;
    color: white;
}