.rdt_TableHeadRow{
    background-color: #0A4468 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #f8f9fa;
}

.rdt_Table{
    line-height: 1.5;
}

.rdt_TableRow{
    color: #0A4468 !important;
    font-size: 16px !important;
}

a:hover {
    color: #0A4468;
}

.link {
    cursor: pointer;
}

.rdt_TableCol_Sortable div {
    text-overflow: unset;
    white-space: break-spaces;
}

.cancelled-row {
    color: #FF0000 !important;
}