#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:1;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}


/* //////////////////////////////////////////// */
#demo {
    display: flex;
    flex-flow: column;
    height: 20vh;
    justify-content: space-around;
}
#loading-spinner {
    display: block;
    margin: 0 auto;
    animation: loading-spinner-spin 2s linear infinite;
    will-change: transform;
}
@keyframes loading-spinner-spin {
    from {
        transform: rotate(0deg);
   }
    to {
        transform: rotate(360deg);
   }
}
#loading-circle {
    stroke-dasharray: 105;
    stroke-dashoffset: 105;
    stroke-linecap: round;
    animation: loading-spinner-small 1.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
    transform: translateZ(0);
    transform-origin: center;
    will-change: stroke-dashoffset;
}
@keyframes loading-spinner-small {
    0% {
        stroke-dashoffset: 95;
        transform: scaleY(1);
   }
    49.99% {
        stroke-dashoffset: 0;
        transform: scaleY(1);
   }
    50% {
        stroke-dashoffset: 0;
        transform: scaleY(-1) rotate(25deg);
   }
    100% {
        stroke-dashoffset: 95;
        transform: scaleY(-1) rotate(-32deg);
   }
}
#loading-circle-meduim {
    stroke-dasharray: 160;
    stroke-dashoffset: 160;
    stroke-linecap: round;
    animation: loading-spinner-meduim 1.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
    transform: translateZ(0);
    transform-origin: center;
    will-change: stroke-dashoffset;
}
@keyframes loading-spinner-meduim {
    0% {
        stroke-dashoffset: 143;
        transform: scaleY(1);
   }
    49.99% {
        stroke-dashoffset: 0;
        transform: scaleY(1);
   }
    50% {
        stroke-dashoffset: 0;
        transform: scaleY(-1) rotate(25deg);
   }
    100% {
        stroke-dashoffset: 143;
        transform: scaleY(-1) rotate(-32deg);
   }
}
#loading-circle-large {
    stroke-dasharray: 210;
    stroke-dashoffset: 210;
    stroke-linecap: round;
    animation: loading-spinner-large 1.7s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
    transform: translateZ(0);
    transform-origin: center;
    will-change: stroke-dashoffset;
}
@keyframes loading-spinner-large {
    0% {
        stroke-dashoffset: 190;
        transform: scaleY(1);
   }
    49.99% {
        stroke-dashoffset: 0;
        transform: scaleY(1);
   }
    50% {
        stroke-dashoffset: 0;
        transform: scaleY(-1) rotate(25deg);
   }
    100% {
        stroke-dashoffset: 190;
        transform: scaleY(-1) rotate(-32deg);
   }
}

/* //////////////////////////////////////////// */