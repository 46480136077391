.rdt_TableHeadRow {
  background-color: #0a4468 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #f8f9fa;
}

.rdt_Table {
  line-height: 1.5;
}

.rdt_TableRow {
  color: #0a4468 !important;
  font-size: 16px !important;
}

a:hover {
  color: #0a4468;
}

.link {
  cursor: pointer;
}

.rdt_TableCol_Sortable div {
    text-overflow: unset;
    white-space: break-spaces;
}

.select-status{
  width: auto !important;
}

@media only screen and (max-width: 600px){
    .select-status{
        width: auto;
    }
}

.cancelled-row {
  color: #FF0000 !important;
}