.download-pdf {
  cursor: pointer;
}
.badge-custom{
  background-color: #0A4468 !important;
  font-size: 0.8rem;
}

.badge-custom-report{
  font-size: 0.8rem;
}