.list-group-item {
    border: none;
    padding-right: 5%;
}

.profile-checkbox-input .form-check-input:disabled {
    opacity: 1;
}

.profile-checkbox-input > label {
    opacity: 1 !important;
}

.error {
    color: red;
    font-weight: bold;
}