*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.blue{
    color: #0A4468 !important;
}

.font_18{
    font-size: 18px !important;
}

.hr{
    border-bottom: 1px solid #E5E5E5;
}

/* utility class end */

.main_hr{
    margin-top: -22px;   
}

.assign_p p{
    margin: 0;
}

.buttons a{
    text-decoration: none;
}

.buttons .btn{
    border-radius: 0;
}

.accept{
    background: #0A4468;
    color: white;
    padding: 11px 14px;
}

.reject{
    background: #74ABCE;
    color: white;
    padding: 11px 14px;
}

.foot_text{
    display: flex;
}

.footer_d{
    display: flex;
}

.fb{
    height: 40px !important;
    width: 40px !important;
}

.assignment{
    min-height: 700px;
}

.assignment-message-info{
    margin-top: 35%;
    text-align: center;
    font-weight: bold;
}

@media(max-width: 992px){
    .main_hr{
        margin-top: -18px;
    }

    .footer_d{
        display: block;
    }
}

@media(max-width: 767px){
    .main_hr{
        margin-top: -14px;
    }

    .foot_text{
        display: flex;
    }

    .f_none{
        display: none;
    }
    
}

@media(max-width: 420px){
    .main_hr{
        margin-top: -11px;
    }
}

@media(max-width: 393px){
    .main_hr{
        margin-top: -10px;
    }
}