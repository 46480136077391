* {
  box-sizing: border-box;
}
.button__icon {
  width: 0.9em;
  height: 0.9em;
  fill: currentcolor;
  margin-right: 0.5em;
}
.button__icon--end {
  margin-right: 0;
  margin-left: 0.5em;
}
.button-custom {
  text-decoration: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  font-size: 1.5rem;
  /* background-color: #0a4468; */
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18);
  text-align: center;
  line-height: 1.1;
  transition: 220ms all ease-in-out;
}
.button-custom:not(.icon-button) {
  padding: 0.25em 0.75em;
  min-width: 10ch;
  min-height: 44px;
}
.button-custom:hover, .button-custom:active {
  background-color: #083653;
}
.button-custom:focus {
  outline: none;
  box-shadow: 0 0 0 4px #06293e;
}
.button-custom--small {
  font-size: 1.15rem;
}
.icon-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.35em;
}
.icon-button__icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.no-border {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
