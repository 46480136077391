.date-time {
  border-right: 1px solid #e1e1e1;
}

.custom-number input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number input[type='number'] {
  -moz-appearance: textfield;
}

.custom-filetype{
  width: 100%;
}

.date-time-error {
  border: 2px solid red;
}