*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body{
    font-family: 'Lato', sans-serif;
}

a{
    text-decoration: none;
    color: inherit;
}

/* utility class start */

.work_sans{
    font-family: 'Work Sans', sans-serif !important;
}

.montserrat{
    font-family: 'Montserrat', sans-serif !important;
}

.blue{
    color: #0A4468 !important;
}

.blue_light{
    color: #195A94 !important;
}

.light_blue{
    color: #74ABCE !important;
}

.gray{
    color: #747474 !important;
}

.white{
    color: #FFFFFF !important;
}

.font_18{
    font-size: 18px !important;
}

.font_22{
    font-size: 22px !important;
}

.font_24{
    font-size: 24px !important;
}

.font_600{
    font-weight: 600 !important;
}

.font_700{
    font-weight: 700 !important;
}

.hr{
    border-bottom: 1px solid #E5E5E5;
}

.form-select{
    color: #0A4468;
    border: 1px solid #E1E1E1;
    font-family: 'Work Sans', sans-serif !important;
}

.form-control{
    border: 1px solid #E1E1E1;
}

.form-select:focus{
    box-shadow: none;
    border-color: #E1E1E1;
}

/* utility class end */

/* =============================================
log in page styling starts here
============================================= */

.login_box{
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    padding: 50px 20px 30px 20px;
}

.login_box .btn{
    border-radius: 0;
}

/* =============================================
log in page styling ends here
============================================= */

/* =============================================
index page styling starts here
============================================= */

.main_hr{
    margin-top: -22px;   
}

.assign_p p{
    margin: 0;
}

.buttons a{
    text-decoration: none;
}

.buttons .btn{
    border-radius: 0;
}

.accept{
    background: #0A4468;
    color: white;
    padding: 11px 14px;
}

.reject{
    background: #74ABCE;
    color: white;
    padding: 11px 14px;
}

.bg_blue{
    background: #F9FBFC !important;
}

.foot_text{
    display: flex;
}

.footer_d{
    display: flex;
}
.content{
    min-height: calc(100vh - 13rem);
}

.fb{
    height: 40px !important;
    width: 40px !important;
}

@media(max-width: 992px){
    .main_hr{
        margin-top: -18px;
    }

    .footer_d{
        display: block;
    }
}

@media(max-width: 767px){
    .main_hr{
        margin-top: -14px;
    }

    .foot_text{
        display: flex;
    }

    .f_none{
        display: none;
    }
    
}

@media(max-width: 420px){
    .main_hr{
        margin-top: -11px;
    }
}

@media(max-width: 393px){
    .main_hr{
        margin-top: -10px;
    }
}

/* =============================================
index page styling ends here
============================================= */

/* =============================================
home page styling starts here
============================================= */

.navbar-light .navbar-nav .nav-link {
    color: #000000;
}

.nav_home{
    background: #74ABCE;
    border-radius: 7px;
    padding: 3px 15px;
}

.navbar-collapse{
    padding: 15px;
}

.navbar-nav .dropdown{
    background:  #F9FBFC;
    border-radius: 6px;
    padding: 3px 15px;
}

.dropdown-menu{
    border: none;
    box-shadow: 0px 1px 2px rgba(7, 52, 90, 0.03);
}

.alina_img{
    height: 25px;
    width: 25px;
    border-radius: 30px;
}

.btn-status{
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
}

.form-control:focus{
    box-shadow: none;
    border-color: #ced4da;
}

.status_dropdown .dropdown-toggle::after{
    margin-left: 9.255em;
}

.btn:focus{
    box-shadow: none;
}

.f_form ::-webkit-input-placeholder{
    color: #0A4468;
}
  
.f_form ::-moz-placeholder{
    color: #0A4468;
}
 
.f_form :-ms-input-placeholder{
    color: #0A4468;
} 
  
.f_form input:-moz-placeholder{
    color: #0A4468;
}

.table_text_blue table th{
    padding: 15px 10px;
}

.table_text_blue table tbody tr{
    border-bottom: 1px solid whitesmoke;
}

.table_text_blue table td{
    color: #0A4468;
    padding: 15px 10px;
}

.table_blue_bg{
    background: #0A4468;
}

.t_underline{
    border-bottom: 1px solid #0A4468;
}

.t_underline_red{
    border-bottom: 1px solid #FF0000;
}


.table_light_bg{
    background: #F3FAFF;
}

.table_pagination nav{
    padding: 15px 15px 0 15px;
    background: #FFFFFF;
}

.page-item .page-link{
    color: #0A4468 !important;
}

@media(max-width: 1347px) and (min-width:1200px){
    .h_margin{
        margin-right: 12px !important;
    }
}

@media(max-width: 1091px) and (min-width:992px){
    .h_margin{
        margin-right: 0px !important;
    }
}

@media(max-width: 992px){
    .nav_home{
        width: fit-content;
    }

    .res_m{
        margin-left: 17px;
    }

    .navbar-nav .dropdown{
        width: fit-content;
    }

    .resp_p{
        padding-left: 35px;
    }
}

/* =============================================
home page styling ends here
============================================= */

/* =============================================
favorite page styling starts here
============================================= */

.main_search .form-control{
    width: 427px;
}

@media(max-width: 992px){
    .r_margin{
        margin-left: 15px;
    }
}

@media(max-width: 767px){
    .favorite_dis{
        display: block !important;
    }
}

@media(max-width: 520px){
    .main_search .form-control{
        width: 100% !important;
    }
}

/* =============================================
publication page styling starts here
============================================= */

.btn_read_more{
    background: #0A4468;
    border-radius: 8px;
    color: #FFFFFF;
}

/* =============================================
publication page styling ends here
============================================= */

/* =============================================
order page styling starts here
============================================= */

.gray_input input{
    border: 1px solid #E1E1E1;
    background: #F9FBFC;
}

::-webkit-input-placeholder{
    color: #0A4468 !important;
    font-family: 'Work Sans', sans-serif !important;
}

::-moz-placeholder{
    color: #0A4468 !important;
    font-family: 'Work Sans', sans-serif !important;
}

:-ms-input-placeholder{
    color: #0A4468 !important;
    font-family: 'Work Sans', sans-serif !important;
} 
  
input:-moz-placeholder{
    color: #0A4468 !important;
    font-family: 'Work Sans', sans-serif !important;
}

.right_border .input-group .form-control{
    border-right: #FFFFFF !important;
}

.right_border .input-group-text{
    background-color: #FFFFFF;
}

.switch-field {
	display: flex;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #F9FBFC;
	color: #0A4468;
	font-size: 12px;
    font-weight: 500;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: #74ABCE;
	box-shadow: none;
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

.drop-file{
    border: 1px dashed #D6D6D6;
    border-radius: 8px;
    height: 172px;
    width: 100%;
    text-align: center;
    padding-top: 55px;
}

.place-order-hover .btn:hover{
    background: #FFFFFF;
    border-color: #0A4468;
    color: #0A4468;
}

.place-order-hover-2 .btn:hover{
    background: #74ABCE;
    border-color: #0A4468;
    color: #FFFFFF;
}

/* =============================================
order page styling ends here
============================================= */

/* =============================================
assignment page styling starts here
============================================= */

.cancel_header .btn{
    background: #FFD7D7;
    border: 1px solid #FF0000;
    border-radius: 3px;
    color: #FF0000;
    font-weight: 500 !important;
}

.main_assignment p{
    margin: 0;
    padding: 0;
}

.assignment_info{
    margin-bottom: 30px;
}

.assignment_info p{
    margin-bottom: 8px;
}

.assignment_info:last-child{
    margin-bottom: 0px;
}

.assignment_info p:last-child{
    margin: 0;
    padding: 0;
}

/* =============================================
assignment page styling ends here
============================================= */

.assignment_info .form-check-input{
    border: 1px solid #0A4468;
}